import C440 from 'src/components/dashboard_440/component.jsx';
import { C440Provider } from 'src/context/440.jsx';

const Page = () => {
	return (
		<C440Provider>
			<C440 />
		</C440Provider>
	);
};

export default Page;
