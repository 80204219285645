import { memo, useMemo } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, Stack, useMediaQuery } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MenuList from '../MenuList';
import LogoSection from '../LogoSection';
import MiniDrawerStyled from './MiniDrawerStyled';
import Chip from '../../ui-component/extended/Chip';
import { drawerWidth } from '../../store/constant';

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = () => {
	const theme = useTheme();
	const matchUpMd = false;//useMediaQuery(theme.breakpoints.up('md'));
	const matchDownMd = false; //useMediaQuery(theme.breakpoints.down('md'));

	const logo = useMemo(
		() => (
			<Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
				<LogoSection />
			</Box>
		),
		[]
	);

	const drawer = useMemo(
		() => (
			<PerfectScrollbar
				component="div"
				style={{
					height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
					paddingLeft: '16px',
					paddingRight: '16px',
					marginTop: 0,
				}}
			>
				<MenuList />
				<Stack direction="row" justifyContent="center" sx={{ mb: 2 }}>
					<Chip label={process.env.REACT_APP_VERSION} disabled chipcolor="secondary" size="small" sx={{ cursor: 'pointer' }} />
				</Stack>
			</PerfectScrollbar>
		),
		[matchUpMd]
	);

	return (
		<Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }} aria-label="mailbox folders">
			{matchDownMd ? (
				<Drawer
					variant={matchUpMd ? 'persistent' : 'temporary'}
					anchor="left"
					open={true}
					sx={{
						'& .MuiDrawer-paper': {
							mt: matchDownMd ? 0 : 11,
							zIndex: 1099,
							width: drawerWidth,
							background: theme.palette.background.default,
							color: theme.palette.text.primary,
							borderRight: 'none',
						},
					}}
					ModalProps={{ keepMounted: true }}
					color="inherit"
				>
					{matchDownMd && logo}
					{drawer}
				</Drawer>
			) : (
				<MiniDrawerStyled variant="permanent" open={true}>
					{logo}
					{drawer}
				</MiniDrawerStyled>
			)}
		</Box>
	);
};

export default memo(Sidebar);
