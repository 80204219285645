import { useContext,useState } from 'react';

import { Box, Typography, Button, IconButton, FormControl, Select, InputLabel, MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import C420Context from 'src/context/420.jsx';
import ModalsContext from 'src/context/modals.jsx';

import config from 'src/config.json';

const Instance = ({ instance }) => {
	const context = useContext(C420Context);
	const modals_context = useContext(ModalsContext);
	const [masterpieces, set_masterpieces] = useState(instance.masterpieces);

	const masterpieces_list = new Array();
	for(let i=1;i<13;i++) masterpieces_list.push(i);

	const masterpieces_change=(number,position)=>{
		if(position===-1){
			for(let i=0;i<3;i++) if(masterpieces[i]===number) masterpieces[i]=-1;
		} else {
			masterpieces[position]=number;
		}
		set_masterpieces([...masterpieces]);
	}

	let masterpieces_valid=true;
	masterpieces.forEach(i=>{
		if(i===-1) masterpieces_valid=false;
	});

	return (
		<Box key={instance.ip} sx={{ marginBottom: 4 }}>
			<Typography variant="h2" sx={{ margin: '0 0 16px' }}>{instance.ip}</Typography>

			<Typography variant="h3" sx={{ margin: '0 0 16px' }}>Masterpieces</Typography>
			<Box
				sx={{
					display:'flex',
					flexWrap: 'wrap'
				}}
				gap={1}
			>
				{masterpieces_list.map((value,index) => (
					<Box key={index} sx={{
						border: '1px solid #000'
					}}>
						<img
							src={`/masterpieces/${value}_free.jpg`}
							loading="lazy"
							style={{
								width: 162,
							}}
						/>
						<Box sx={{padding:2}}>
							<FormControl fullWidth>
								<InputLabel id={`position${index}`}>Position</InputLabel>
								<Select
									labelId={`position${index}`}
									value={masterpieces.indexOf(value)}
									label="Position"
									onChange={e=>masterpieces_change(value,e.target.value)}
								>
									<MenuItem value={-1}>None</MenuItem>
									<MenuItem value={1}>Left</MenuItem>
									<MenuItem value={0}>Center</MenuItem>
									<MenuItem value={2}>Right</MenuItem>
								</Select>
							</FormControl>
						</Box>
					</Box>
				))}
			</Box>
				{masterpieces_valid?<></>:(<Typography variant="h4" sx={{color:'red', marginTop:'16px'}}>All three positions must be filled</Typography>)}
				<Button variant="contained" color="info" onClick={async ()=>{
					await context.masterpieces(instance.ip,masterpieces);
					modals_context.show('masterpieces_saved');
				}} sx={{ marginTop: 2 }} disabled={!masterpieces_valid}>Save positions</Button>

			<Typography variant="h3" sx={{ margin: '64px 0px 16px' }}>Visitor's drawings</Typography>
			<Box
				sx={{
					display:'flex',
					flexWrap: 'wrap'
				}}
				gap={1}
			>
			{instance.files.map((name,index) => (
				<Box key={name} sx={{
					border: '1px solid #000'
				}}>
					<img
						src={`${config.api}/static/420/${name}`}
						alt={name}
						loading="lazy"
						style={{
							width: 162,
						}}
					/>
					<Box sx={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
						<IconButton
							sx={{ color: 'black' }}
						>
							{index<10?(<VisibilityIcon/>):(<VisibilityOffIcon/>)}
						</IconButton>

						<span style={{fontSize:'12px'}}>{name}</span>
						<IconButton
							aria-label="delete"
							onClick={() => context.delete(instance.ip, name)}
							sx={{ color: 'red' }}
						>
							<DeleteIcon />
						</IconButton>
					</Box>
				</Box>
			))}
			</Box>
			<Button variant="contained" color="error" onClick={() => context.delete_all(instance.ip)} sx={{ marginTop: 2 }}>Delete All</Button>
		</Box>
	);
};

export default Instance;
