import C420 from 'src/components/dashboard_420/component.jsx';
import { C420Provider } from 'src/context/420.jsx';

const Page = () => {
	return (
		<C420Provider>
			<C420 />
		</C420Provider>
	);
};

export default Page;
