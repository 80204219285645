import { useContext } from 'react';

import { Box, Button} from '@mui/material';

import RefreshIcon from '@mui/icons-material/Refresh';

import C440Context from 'src/context/440.jsx';

import Instance from './instance.jsx';

const SectionTimeline = () => {
	const context = useContext(C440Context);

	return (
		<Box sx={{ bgcolor:'#fff', padding: 4, width: '100%',minHeight: 68 }} style={{position:'relative'}}>
			<Button onClick={()=>context.refresh()} variant="contained" startIcon={<RefreshIcon />} sx={{
				position: 'absolute',
				top: 16,
				right: 16
			}}>Refresh</Button>
			{context.data.map((instance, index) => (
				<Instance instance={instance} key={index}/>
			))}
		</Box>
	);
};

export default SectionTimeline;
