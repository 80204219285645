import C910 from 'src/components/dashboard_910/component.jsx';
import { C910Provider } from 'src/context/910.jsx';

const Page = () => {
	return (
		<C910Provider>
			<C910 />
		</C910Provider>
	);
};

export default Page;
