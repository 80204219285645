import React, { useContext } from 'react';
import ModalsContext from '../context/modals.jsx';

const VideoPlayer = () => {
	const {
		data
	} = useContext(ModalsContext);

	return (
		<video width="640" src={data.url} controls style={{width: '100%'}}/>
	);
};

export default VideoPlayer;
