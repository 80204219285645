import React, { useContext } from 'react';

import ModalsContext from 'src/context/modals.jsx';
import SimpleModal from 'src/berry/ui-component/modals/SimpleModals.jsx';
import MasterpiecesSaved from 'src/modals/masterpieces_saved.jsx';
import VideoPlayer from 'src/modals/video_player.jsx';

const big_modal = { width: '900px' };

const _modal2components = new Map([
	['masterpieces_saved', { content: <MasterpiecesSaved />, title: 'Masterpieces Saved' }],
	['video_player', { content: <VideoPlayer />, title: 'Video Preview', styles: big_modal }],
]);

const Modals = () => {
	const { modal, opened, hide } = useContext(ModalsContext);
	const component = _modal2components.get(modal);

	return <SimpleModal open={opened} on_close={hide} content={component?.content} title={component?.title} styles={component?.styles} />;
};

export default Modals;
