import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import UserContext, { UserProvider } from './context/user.jsx';

import SignInPage from './pages/sign_in.jsx';
import Dashboard from './pages/dashboard.jsx';
import DashboardHome from './pages/dashboard_home.jsx';
import Dashboard420 from './pages/dashboard_420.jsx';
import Dashboard440 from './pages/dashboard_440.jsx';
import Dashboard910 from './pages/dashboard_910.jsx';
import { ModalsProvider } from './context/modals.jsx';

const Index = () => {
	const navigate = useNavigate();
	const user_context = useContext(UserContext);

	if (!user_context.loaded) return <p>Loading...</p>;

	if (!user_context.user) {
		navigate('/sign_in');
	} else {
		navigate('/dashboard');
	}
};

export default function App() {
	return (
		<Router>
			<ModalsProvider>
				<UserProvider>
					<Routes>
						<Route exact path="/" element={<Index element />} />
						<Route path="/sign_in" element={<SignInPage />} />
						<Route path="/dashboard" element={<Dashboard />}>
							<Route path="" element={<DashboardHome />} />
							<Route path="420" element={<Dashboard420 />} />
							<Route path="440" element={<Dashboard440 />} />
							<Route path="910" element={<Dashboard910 />} />
						</Route>
					</Routes>
				</UserProvider>
			</ModalsProvider>
		</Router>
	);
}
