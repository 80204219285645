import React, { useContext } from 'react';
import { Button, Typography, Box } from '@mui/material';
import ModalsContext from '../context/modals.jsx';

const MasterpiecesSaved = () => {
	const {
		hide
	} = useContext(ModalsContext);

	return (
		<>
			<Typography variant="h5" textAlign={'center'}>
				Masterpieces selection for this app is saved.
			</Typography>
			<Box textAlign="center">
				<Button variant="contained" type="button" onClick={hide} sx={{ mt: 2 }}>Ok</Button>
			</Box>
		</>
	);
};

export default MasterpiecesSaved;
