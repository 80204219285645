import { memo } from 'react';

// material-ui
import { Typography } from '@mui/material';
import { IconChartArcs, IconPhotoEdit, IconKeyframes, IconVideo } from '@tabler/icons';

// project imports
import NavGroup from './NavGroup';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const menu_items = [
	{
		id: 'dashboard',
		title: 'Dashboard',
		icon: IconChartArcs,
		type: 'group',
		children: [
			{
				id: '420',
				title: '420 | Mini Masterpiece',
				type: 'item',
				url: '/dashboard/420',
				icon: IconPhotoEdit,
			},
			{
				id: '440',
				title: '440 | Stop Animation',
				type: 'item',
				url: '/dashboard/440',
				icon: IconKeyframes,
			},
			{
				id: '910',
				title: '950 & 910 | Invent It',
				type: 'item',
				url: '/dashboard/910',
				icon: IconVideo,
			}
		],
	},
];

const MenuList = () => {
	const navItems = menu_items.map((item) => {
		switch (item.type) {
			case 'group':
				return <NavGroup key={item.id} item={item} />;
			default:
				return (
					<Typography key={item.id} variant="h6" color="error" align="center">
						Menu Items Error
					</Typography>
				);
		}
	});

	return <>{navItems}</>;
};

export default memo(MenuList);
