import React, { useState, useContext, useEffect } from 'react';

import UserContext from './user.jsx';

import api from '../api/420.js';

import { CircularProgress } from '@mui/material';

const C420Context = React.createContext();

function C420Provider({ children }) {
	const [data, data_set] = useState([]);
	const { session } = useContext(UserContext);

	const store = {
		get data() {
			return data;
		},
		delete:async (ip,name)=>{
			await api.delete({session,ip,name});
			data.forEach(instance=>{
				if(instance.ip!==ip) return;
				const index = instance.files.indexOf(name);
				if(index!==-1) instance.files.splice(index,1);
			});
			data_set([...data]);

			store.refresh();
		},
		delete_all:async (ip,name)=>{
			await api.delete_all({session,ip});
			data.forEach(instance=>{
				if(instance.ip!==ip) return;
				instance.files=[];
			});
			data_set([...data]);

			store.refresh();
		},
		masterpieces: async (ip,masterpieces)=>{
			await api.masterpieces({session,ip,masterpieces});
			data.forEach(instance=>{
				if(instance.ip!==ip) return;
				instance.masterpieces=masterpieces;
			});
			data_set([...data]);

			store.refresh();
		},
		refresh: async ()=>{
			try{
				data_set(await api.data({ session }));
			} catch(e){
				data_set([]);
			}
		}
	};

	useEffect(() => {
		store.refresh();
	}, [1]);

	return <C420Context.Provider value={store}>{children}</C420Context.Provider>;
}

function C420Deffer({ children }) {
	const context = useContext(C420Context);

	if (!context.data.length) return <CircularProgress size={100} sx={{ mx: 'auto', mt: 20, display: 'block' }} />;

	return children;
}

export default C420Context;
export { C420Provider, C420Deffer };
