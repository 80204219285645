import { useContext,useState } from 'react';

import { Box, Typography, Button, IconButton, FormControl, Select, InputLabel, MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import C910Context from 'src/context/910.jsx';
import ModalsContext from 'src/context/modals.jsx';

import config from 'src/config.json';

const Instance = ({ instance }) => {
	const context = useContext(C910Context);
	const modals_context = useContext(ModalsContext);

	return (
		<Box key={instance.ip} sx={{ marginBottom: 4 }}>
			<Typography variant="h2" sx={{ margin: '0 0 16px' }}>{instance.ip}</Typography>

			<Typography variant="h3" sx={{ margin: '64px 0px 16px' }}>Visitor's recordings</Typography>
			<Box
				sx={{
					display:'flex',
					flexWrap: 'wrap'
				}}
				gap={1}
			>
			{instance.files.map((name,index) => (
				<Box key={name} sx={{
					border: '1px solid #000'
				}}>
					<img alt={name} src={`${config.api}/static/910/${name.split('.')[0]}.jpg`}
						style={{
							display: 'block',
							width: 220,
							cursor: 'pointer',
							background: 'url(/video.jpg) center center no-repeat',
							backgroundSize: 'contain',
							aspectRatio: '16 / 9'
						}}
						onClick={()=>modals_context.show('video_player',{url:`${config.api}/static/910/${name}`})}
					/>
					<Box sx={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
						<IconButton
							sx={{ color: 'black' }}
						>
							{index<20?(<VisibilityIcon/>):(<VisibilityOffIcon/>)}
						</IconButton>

						<span style={{fontSize:'12px'}}>{name}</span>
						<IconButton
							aria-label="delete"
							onClick={() => context.delete(instance.ip, name)}
							sx={{ color: 'red' }}
						>
							<DeleteIcon />
						</IconButton>
					</Box>
				</Box>
			))}
			</Box>
			<Button variant="contained" color="error" onClick={() => context.delete_all(instance.ip)} sx={{ marginTop: 2 }}>Delete All</Button>
		</Box>

	);
};

export default Instance;
